<script setup>
import { Link, usePage } from '@inertiajs/vue3';
import { onMounted, computed } from 'vue';

const emit = defineEmits(['changeSidebarState', 'changeStateResponsiveSidebar']);
const props = defineProps({
    open: Boolean,
});

const changeSidebarState = () => emit('changeSidebarState');
const changeStateResponsiveSidebar = () => emit('changeStateResponsiveSidebar');

const checkActiveLinks = () => {
    const links = document.querySelectorAll('.nav-main-link');
    const currentUrl = window.location.pathname;

    links.forEach((link) => {
        link.classList.remove('active');
        const hrefs = link.dataset?.destinationUrls?.split(',') || [];

        if (hrefs.some((url) => currentUrl.startsWith(url))) {
            link.classList.add('active');
        }
    });
};

const closeAllCollapse = () => {
    const links = document.querySelectorAll('.sidebar .nav-link');
    links.forEach((link) => {
        link.setAttribute('aria-expanded', 'false');
    });
};

const page = usePage();
const churchManagement = computed(() => page.props.church_management);

onMounted(() => {
    checkActiveLinks();
    closeAllCollapse();
});

const environment = import.meta.env.VITE_ENVIRONMENT;
</script>

<template>
    <div class="sidebar" :class="{ 'mt-3': environment === 'staging' }">
        <div class="sidebar-header">
            <Link href="/dashboard" class="sidebar-brand"> SGI <span> - Verbo da Vida</span> </Link>
            <div
                class="sidebar-toggler d-none d-lg-block"
                :class="open ? 'not-active' : 'active'"
                @click="changeSidebarState"
            >
                <span />
                <span />
                <span />
            </div>
            <span class="d-lg-none" @click="changeStateResponsiveSidebar"
                ><font-awesome-icon class="text-white" :icon="['fas', 'bars']"
            /></span>
        </div>
        <div class="sidebar-body">
            <ul class="nav">
                <li class="nav-item nav-category">Principal</li>

                <li
                    class="nav-item"
                    :class="{
                        active: $page.component === 'Dashboard/Pages/Home',
                    }"
                >
                    <Link href="/dashboard" class="nav-link">
                        <font-awesome-icon class="link-icon" :icon="['fas', 'layer-group']" />
                        <span class="link-title">Visão geral</span>
                    </Link>
                </li>

                <li class="nav-item nav-category">Recepção</li>

                <li
                    class="nav-item nav-main-link"
                    data-destination-urls="/dashboard/visitors,/dashboard/visitors/create"
                >
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#visitors"
                        role="button"
                        aria-controls="visitors"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'person-shelter']" />
                        <span class="link-title">Visitantes</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div id="visitors" class="collapse">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/visitors" class="nav-link">
                                    Listagem completa
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/visitors/create" class="nav-link">
                                    Criar novo
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>

                <li class="nav-item nav-category">Diáconos</li>

                <li class="nav-item nav-main-link" data-destination-urls="/dashboard/census">
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#census"
                        role="button"
                        aria-controls="census"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'chart-column']" />
                        <span class="link-title">Censo</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div id="census" class="collapse">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/census" class="nav-link">
                                    Listagem completa
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/census/create" class="nav-link">
                                    Criar novo
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>

                <li class="nav-item nav-category">Conselheiros</li>

                <li
                    class="nav-item nav-main-link"
                    data-destination-urls="/dashboard/new-births,/dashboard/new-births/create"
                >
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#new_births"
                        role="button"
                        aria-controls="new_births"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'person-praying']" />
                        <span class="link-title">Novos nascimento</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div id="new_births" class="collapse">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/new-births" class="nav-link">
                                    Listagem completa
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/new-births/create" class="nav-link">
                                    Criar novo
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>

                <li
                    class="nav-item nav-main-link"
                    data-destination-urls="/dashboard/baptism-holy-spirits/create,/dashboard/baptism-holy-spirits"
                >
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#baptism_holy_spirit"
                        role="button"
                        aria-controls="baptism_holy_spirit"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'person-rays']" />
                        <span class="link-title">Batismos ES</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div id="baptism_holy_spirit" class="collapse">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/baptism-holy-spirits" class="nav-link">
                                    Listagem completa
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link
                                    href="/dashboard/baptism-holy-spirits/create"
                                    class="nav-link"
                                >
                                    Criar novo
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>

                <li class="nav-item nav-category">Central de Atendimento</li>

                <li
                    class="nav-item nav-main-link"
                    data-destination-urls="/dashboard/members,/dashboard/members/create "
                >
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#members"
                        role="button"
                        aria-controls="members"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'user']" />
                        <span class="link-title">Membros</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div id="members" class="collapse">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/members" class="nav-link">
                                    Listagem completa
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/members/create" class="nav-link">
                                    Criar novo
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>

                <li
                    class="nav-item nav-main-link"
                    data-destination-urls="/dashboard/families/create,/dashboard/families"
                >
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#families"
                        role="button"
                        aria-controls="families"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'people-roof']" />
                        <span class="link-title">Famílias</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div id="families" class="collapse">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/families" class="nav-link">
                                    Listagem completa
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/families/create" class="nav-link">
                                    Criar novo
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>

                <li class="nav-item">
                    <Link href="/dashboard/events?type=PAID_EVENT" class="nav-link">
                        <font-awesome-icon class="link-icon" :icon="['fas', 'calendar-check']" />
                        <span class="link-title">Eventos pagos</span>
                    </Link>
                </li>

                <li
                    class="nav-item nav-main-link"
                    data-destination-urls="/dashboard/water-baptisms,/dashboard/water-baptisms/create"
                >
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#baptisms"
                        role="button"
                        aria-controls="baptisms"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'person-drowning']" />
                        <span class="link-title">Turmas batismo</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div id="baptisms" class="collapse">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/water-baptisms" class="nav-link">
                                    Listagem completa
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/water-baptisms/create" class="nav-link">
                                    Criar novo
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>

                <li
                    class="nav-item nav-main-link"
                    data-destination-urls="/dashboard/departments/entry-requests,/dashboard/departments/entry-requests/create"
                >
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#workers"
                        role="button"
                        aria-controls="workers"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'user-tie']" />
                        <span class="link-title">Obreiros</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div id="workers" class="collapse">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/departments/entry-requests" class="nav-link">
                                    Solicitações
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link
                                    href="/dashboard/departments/entry-requests/create"
                                    class="nav-link"
                                >
                                    Criar nova
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>

                <li
                    class="nav-item nav-main-link"
                    data-destination-urls="/dashboard/children-consecration,/dashboard/children-consecration/create"
                >
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#childs"
                        role="button"
                        aria-controls="childs"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'children']" />
                        <span class="link-title">Consagração crianças</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div id="childs" class="collapse">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/children-consecration" class="nav-link">
                                    Listagem completa
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link
                                    href="/dashboard/children-consecration/create"
                                    class="nav-link"
                                >
                                    Criar nova
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>

                <li class="nav-item nav-category">Comunicação</li>

                <li class="nav-item nav-main-link" data-destination-urls="/dashboard/schedules">
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#schedules"
                        role="button"
                        aria-controls="schedules"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'list']" />
                        <span class="link-title">Programações</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div id="schedules" class="collapse">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/schedules" class="nav-link">
                                    Listagem completa
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/schedules/create" class="nav-link">
                                    Criar novo
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>

                <li class="nav-item nav-main-link" data-destination-urls="/dashboard/events">
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#events"
                        role="button"
                        aria-controls="events"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'calendar']" />
                        <span class="link-title">Eventos</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div id="events" class="collapse">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/events" class="nav-link">
                                    Listagem completa
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/events/create" class="nav-link">
                                    Criar novo
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>

                <li class="nav-item nav-main-link" data-destination-urls="/dashboard/ministers">
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#ministers"
                        role="button"
                        aria-controls="ministers"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'people-line']" />
                        <span class="link-title">Ministros</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div id="ministers" class="collapse">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/ministers" class="nav-link">
                                    Listagem completa
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/ministers/create" class="nav-link">
                                    Criar novo
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>

                <li class="nav-item nav-main-link" data-destination-urls="/dashboard/leaders">
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#leaders"
                        role="button"
                        aria-controls="leaders"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'building-user']" />
                        <span class="link-title">Dirigentes</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div id="leaders" class="collapse">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/leaders" class="nav-link">
                                    Listagem completa
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/leaders/create" class="nav-link">
                                    Criar novo
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>

                <li class="nav-item nav-category">Louvor</li>

                <li
                    class="nav-item nav-main-link"
                    data-destination-urls="/dashboard/musical-plannings,/dashboard/musical-plannings/create"
                >
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#musical-plannings"
                        role="button"
                        aria-controls="musical-plannings"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'sliders']" />
                        <span class="link-title">Planejamentos</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div id="musical-plannings" class="collapse">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/musical-plannings" class="nav-link">
                                    Listagem completa
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/musical-plannings/create" class="nav-link">
                                    Criar novo
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>

                <li
                    class="nav-item nav-main-link"
                    data-destination-urls="/dashboard/musics,/dashboard/musics/create"
                >
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#musics"
                        role="button"
                        aria-controls="musics"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'music']" />
                        <span class="link-title">Músicas</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div id="musics" class="collapse">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/musics" class="nav-link">
                                    Listagem completa
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/musics/create" class="nav-link">
                                    Criar novo
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>

                <li
                    class="nav-item nav-main-link"
                    data-destination-urls="/dashboard/artists,/dashboard/artists/create"
                >
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#artists"
                        role="button"
                        aria-controls="artists"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'users']" />
                        <span class="link-title">Artistas</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div id="artists" class="collapse">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/artists" class="nav-link">
                                    Listagem completa
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/artists/create" class="nav-link">
                                    Criar novo
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>

                <li
                    class="nav-item nav-main-link"
                    data-destination-urls="/dashboard/musical-themes,/dashboard/musical-themes/create"
                >
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#musical-themes"
                        role="button"
                        aria-controls="musical-themes"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'compact-disc']" />
                        <span class="link-title">Temas</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div id="musical-themes" class="collapse">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/musical-themes" class="nav-link">
                                    Listagem completa
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/musical-themes/create" class="nav-link">
                                    Criar novo
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>

                <li
                    class="nav-item nav-main-link"
                    data-destination-urls="/dashboard/color-palettes-praise,/dashboard/color-palettes-praise/create"
                >
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#color-palettes-praise"
                        role="button"
                        aria-controls="color-palettes-praise"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'palette']" />
                        <span class="link-title">Paletas cores</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div id="color-palettes-praise" class="collapse">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/color-palettes-praise" class="nav-link">
                                    Listagem completa
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link
                                    href="/dashboard/color-palettes-praise/create"
                                    class="nav-link"
                                >
                                    Criar novo
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>

                <li class="nav-item nav-category">Departamentos</li>

                <li
                    class="nav-item nav-main-link"
                    data-destination-urls="/dashboard/departments,/dashboard/departments/create"
                >
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#departments"
                        role="button"
                        aria-controls="departments"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'hotel']" />
                        <span class="link-title">Departamentos</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div id="departments" class="collapse">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/departments" class="nav-link">
                                    Listagem completa
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/departments/create" class="nav-link">
                                    Criar novo
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>

                <li
                    class="nav-item nav-main-link"
                    data-destination-urls="/dashboard/counselors,/dashboard/counselors/create"
                >
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#counselors"
                        role="button"
                        aria-controls="counselors"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'comments']" />
                        <span class="link-title">Conselheiros</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div id="counselors" class="collapse">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/counselors" class="nav-link">
                                    Listagem completa
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/counselors/create" class="nav-link">
                                    Criar novo
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>

                <li class="nav-item nav-category">Evangelizações</li>

                <li
                    class="nav-item nav-main-link"
                    data-destination-urls="/dashboard/hospital-verb-visits"
                >
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#hospital-verb-visits"
                        role="button"
                        aria-controls="hospital-verb-visits"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'hospital']" />
                        <span class="link-title">Verbo Hospital</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div id="hospital-verb-visits" class="collapse">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/hospital-verb-visits" class="nav-link">
                                    Listagem completa
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link
                                    href="/dashboard/hospital-verb-visits/create"
                                    class="nav-link"
                                >
                                    Criar novo
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>

                <li
                    class="nav-item nav-main-link"
                    data-destination-urls="/dashboard/hospital-verb-members"
                >
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#hospital-verb-members"
                        role="button"
                        aria-controls="hospital-verb-members"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'people-group']" />
                        <span class="link-title">Equipe</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div id="hospital-verb-members" class="collapse">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/hospital-verb-members" class="nav-link">
                                    Listagem completa
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link
                                    href="/dashboard/hospital-verb-members/create"
                                    class="nav-link"
                                >
                                    Criar novo
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>

                <li
                    class="nav-item nav-main-link"
                    data-destination-urls="/dashboard/evangelizations"
                >
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#evangelizations"
                        role="button"
                        aria-controls="evangelizations"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'cross']" />
                        <span class="link-title">Evangelizações</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div id="evangelizations" class="collapse">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/evangelizations" class="nav-link">
                                    Listagem completa
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/evangelizations/create" class="nav-link">
                                    Criar novo
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>

                <template v-if="churchManagement">
                    <li class="nav-item nav-category">Igrejas</li>

                    <li class="nav-item nav-main-link" data-destination-urls="/dashboard/churches">
                        <a
                            class="nav-link"
                            data-bs-toggle="collapse"
                            href="#churches"
                            role="button"
                            aria-controls="churches"
                        >
                            <font-awesome-icon class="link-icon" :icon="['fas', 'church']" />
                            <span class="link-title">Igrejas</span>
                            <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                        </a>
                        <div id="churches" class="collapse">
                            <ul class="nav sub-menu">
                                <li class="nav-item">
                                    <Link href="/dashboard/churches" class="nav-link">
                                        Listagem completa
                                    </Link>
                                </li>
                                <li class="nav-item">
                                    <Link href="/dashboard/churches/create" class="nav-link">
                                        Criar nova
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                </template>

                <!-- <li class="nav-item nav-category">Igreja</li>

                <li class="nav-item nav-main-link" data-destination-urls="/dashboard/deacons">
                    <a class="nav-link" data-bs-toggle="collapse" href="#deacons" role="button" aria-controls="deacons">
                        <font-awesome-icon class="link-icon" :icon="['fas', 'user-tie']" />
                        <span class="link-title">Diáconos</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div class="collapse" id="deacons">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/deacons" class="nav-link">Listagem completa</Link>
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/deacons/create" class="nav-link">Criar novo</Link>
                            </li>
                        </ul>
                    </div>
                </li>

                <li class="nav-item nav-main-link" data-destination-urls="/dashboard/church">
                    <a class="nav-link" data-bs-toggle="collapse" href="#church" role="button" aria-controls="church">
                        <font-awesome-icon class="link-icon" :icon="['fas', 'landmark']" />
                        <span class="link-title">Patrimônio</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div class="collapse" id="church">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/church" class="nav-link">Listagem completa</Link>
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/church/create" class="nav-link">Criar novo</Link>
                            </li>
                        </ul>
                    </div>
                </li> -->

                <li class="nav-item nav-category">Equipe</li>

                <li class="nav-item nav-main-link" data-destination-urls="/dashboard/users">
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#users"
                        role="button"
                        aria-controls="users"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'people-group']" />
                        <span class="link-title">Membros</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div id="users" class="collapse">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/users" class="nav-link">
                                    Listagem completa
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/users/create" class="nav-link">
                                    Criar novo
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/users/organization-chart" class="nav-link">
                                    Organograma
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>

                <li class="nav-item nav-main-link" data-destination-urls="/dashboard/roles">
                    <a
                        class="nav-link"
                        data-bs-toggle="collapse"
                        href="#roles"
                        role="button"
                        aria-controls="roles"
                    >
                        <font-awesome-icon class="link-icon" :icon="['fas', 'user-gear']" />
                        <span class="link-title">Funções</span>
                        <font-awesome-icon class="link-arrow" :icon="['fas', 'angle-down']" />
                    </a>
                    <div id="roles" class="collapse">
                        <ul class="nav sub-menu">
                            <li class="nav-item">
                                <Link href="/dashboard/roles" class="nav-link">
                                    Listagem completa
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link href="/dashboard/roles/create" class="nav-link">
                                    Criar novo
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
