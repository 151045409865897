<template>
    <Head title="Hora Atual" />
    <div
        id="clock"
        class="text-center vh-100 d-flex align-items-center justify-content-center bg-black"
    >
        <div>
            <h1 class="font-bold text-white">{{ currentTime }}</h1>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

import { Head } from '@inertiajs/vue3';

const getFormattedTime = () => {
    return new Date().toLocaleTimeString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });
};

const currentTime = ref(getFormattedTime());

const updateTime = () => {
    currentTime.value = getFormattedTime();
};

let timer = null;

onMounted(() => {
    timer = setInterval(updateTime, 1000);
});

onUnmounted(() => {
    clearInterval(timer);
});
</script>
